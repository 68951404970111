import { GetStaticPaths, GetStaticProps } from 'next'
import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { AutoBreadcrumb } from '@/components/_layout/AutoBreadcrumb'
import { AutoSeo } from '@/components/_layout/AutoSeo'
import {
  ContainerError,
  ContainerLoading,
  ContainerNotFound,
} from '@/components/_layout/ContainerMessage'
import { MainLayout } from '@/components/_layout/MainLayout'
import { PromoBannerSlice } from '@/components/_shared/_slice/PromoBannerSlice'
import { CancelBookingModal } from '@/components/booking/CancelBookingModal'
import { LessonAreaListSelectorSlice } from '@/components/lesson/_slice/LessonAreaListSelectorSlice'
import { LessonDescriptionSlice } from '@/components/lesson/_slice/LessonDescriptionSlice'
import { LessonHeroSlice } from '@/components/lesson/_slice/LessonHeroSlice'
import { LessonOnlineAboutSlice } from '@/components/lesson/_slice/LessonOnlineAboutSlice'
import { DynamicSurveyFeedbackListCarouselSlice } from '@/components/survey/_slice/DynamicSurveyFeedbackListCarouselSlice'
import { TeacherDetailSlice } from '@/components/teacher/_slice/TeacherDetailSlice'
import { LessonBookingContextProvider } from '@/contexts/lessonBooking'
import {
  SWR_KEY__LESSON_RESULT,
  useSWRLessonResult,
} from '@/hooks/swr/useSwr-lessonResult'
import { useEffectOnce } from '@/hooks/use-effectOnce'
import { INextPageWithLayout } from '@/pages/_app'
import { getLessonResult, ILessonResultVariables } from '@/services/api-graphql-lesson'
import { viewContentGTMEvent } from '@/services/gtm'
import { isValidUUIDv4 } from '@/utils/misc'
import { buildInstructorRoute } from '@/utils/route'
import { serializeSwrApiKey } from '@/utils/swr'

interface IProps {
  lessonUuid: string
}

const LessonLivePage: INextPageWithLayout<IProps> = ({ lessonUuid }) => {
  const { lessonLoading, lesson, lessonError } = useSWRLessonResult({
    gqlQuery: 'Online',
    lessonUuid,
  })
  const intl = useIntl()

  useEffectOnce(() => {
    if (!lesson) {
      return
    }

    viewContentGTMEvent({ lesson })
  }, lesson)

  if (lessonLoading && !lesson) return <ContainerLoading />
  if (!lesson) return <ContainerNotFound />
  if (lessonError) return <ContainerError />

  return (
    <>
      <AutoSeo lesson={lesson} />
      <AutoBreadcrumb lesson={lesson} />

      <LessonBookingContextProvider>
        <LessonHeroSlice lesson={lesson} />
        <CancelBookingModal />
      </LessonBookingContextProvider>

      <LessonDescriptionSlice lesson={lesson} />
      <TeacherDetailSlice
        teacher={lesson.teacher}
        buttonText={intl.formatMessage({
          defaultMessage: 'Voir ses cours',
          description: 'LessonLivePage > TeacherDetailSlice: button',
        })}
        buttonLink={buildInstructorRoute(lesson.teacher)}
      />

      <LessonOnlineAboutSlice lesson={lesson} />
      <PromoBannerSlice />

      {lesson.serie?.ulid && (
        <DynamicSurveyFeedbackListCarouselSlice
          params={{ serieUuid: lesson.serie.ulid }}
        />
      )}

      <LessonAreaListSelectorSlice />
    </>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const lessonUuid = params.uuid.toString()
  if (!isValidUUIDv4(lessonUuid)) {
    return {
      notFound: true,
      revalidate: 60, // 1mn
    }
  }

  const gqlVariables = {
    gqlQuery: 'Online',
    lessonUuid,
  } as ILessonResultVariables
  const lesson = await getLessonResult(gqlVariables)

  if (!lesson) {
    return {
      notFound: true,
      revalidate: 60, // 1mn
    }
  }

  return {
    props: {
      lessonUuid,
      swrFallback: {
        [serializeSwrApiKey(SWR_KEY__LESSON_RESULT, gqlVariables)]: lesson,
      },
    },
    revalidate: lesson.archivedAt ? 604800 : 3600, // 7days or 1h
  }
}

LessonLivePage.getLayout = (page: ReactElement) => (
  <MainLayout disableAutoSeo disableAutoBreadcrumb>
    {page}
  </MainLayout>
)

// eslint-disable-next-line import/no-default-export
export default LessonLivePage
