import { KeyedMutator, SWRConfiguration } from 'swr'

import { getLessonResult, ILessonResultVariables } from '@/services/api-graphql-lesson'
import { ILesson, ILessonDecorated } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'
import { decorateLesson } from '@/utils/decorator'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__LESSON_RESULT = 'lesson_result'

interface IReturnType {
  lessonLoading: boolean
  lesson: ILessonDecorated
  lessonError: HTTPError
  lessonValidating: boolean
  mutateLesson: KeyedMutator<ILesson>
}

export const useSWRLessonResult = (
  gqlVariables: ILessonResultVariables,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false, keepPreviousData: true }
): IReturnType => {
  const { authLoading, payload } = useSWRAuth()
  const { data, error, mutate, isLoading, isValidating } = useSWRCustom<ILesson>(
    [gqlVariables.lessonUuid && SWR_KEY__LESSON_RESULT, payload?.uuid],
    getLessonResult,
    gqlVariables,
    swrConfig
  )

  return {
    lessonLoading: authLoading || isLoading,
    lesson: data && decorateLesson(data, mutate),
    lessonError: error,
    lessonValidating: isValidating,
    mutateLesson: mutate,
  }
}
